/**
 * The rr blog icon
 * @returns SVG
 */
export default function svgRightArrow(): JSX.Element {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path
                    d="M5.625 11.6943L9.375 7.94434L5.625 4.19434"
                    stroke="#216600"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
