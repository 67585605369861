/**
 * The Carousel Right icon
 * @returns SVG
 */

export default function CarouselRight(): JSX.Element {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M9.5775 18L15.7465 12L9.5775 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
