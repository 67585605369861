/**
 * The Carousel Right icon
 * @returns SVG
 */

export default function CarouselLeft(): JSX.Element {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M15.4226 18L9.25354 12L15.4226 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
