import styles from '@styles/common-scss/_tagsListNoExpand.module.scss';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import React from 'react';

interface Tag {
    name: string;
    link?: string;
    color: string;
    slug?: string;
    numberOfSites?: number;
}

export default function TagListNoExpand({ tag, numberOfSites }: { tag: Tag; numberOfSites: boolean }): JSX.Element {
    const router = useRouter();

    // Get GTM data-event-action by router path. Default is: review
    const findActionGTM = (router: NextRouter) => {
        if (router.asPath === '/categories') {
            return 'category';
        } else if (router.asPath.indexOf('/porn/guides/') > -1) {
            return 'GuideTags';
        } else {
            return 'review';
        }
    };
    // Get Tag Manager event label by router
    const actionGTM = findActionGTM(router);

    return (
        <>
            <div className={styles.wrapper}>
                {/* Generate bubble tag without read more expansion */}
                <div className={styles.container}>
                    <Link href={process.env.NEXT_PUBLIC_APP_DOMAIN + (tag.link ? tag.link : `/porn/tag/${tag.slug}`)}>
                        <a data-event-category="User Action" data-event-action={`${actionGTM}-tags`} data-event-label={tag.name}>
                            <div
                                style={{
                                    border: tag.color ? `1px solid ${tag.color}` : '',
                                    color: tag.color ? tag.color : ''
                                }}
                                className={styles.tag}>
                                {tag.name}
                                {numberOfSites ? <p className={styles.numberOfSites}>{tag.numberOfSites}</p> : ''}
                            </div>
                        </a>
                    </Link>
                </div>
            </div>
        </>
    );
}
