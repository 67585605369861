import styles from '@styles/common-scss/card/_videoCard.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import aa from 'search-insights';

interface Type {
    site: any;
    index?: number;
    pageTag?: string;
    eventSender?: (arg0: string, arg1: any, arg3: string) => void;
    eventData?: any;
}

const PrepareLink = React.memo(({ text, slug, type }: { text: string; slug: string; type: string }) => {
    if (slug === '') {
        return <div className={styles.right}>{text}</div>;
    } else {
        let href = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/${type}/${slug.toLocaleLowerCase()}`;

        if (type === 'categories') {
            href = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn${slug.toLocaleLowerCase()}`;
        }
        return (
            <Link href={href} passHref>
                <a className={styles.right} title={`link to ${text} sites`}>
                    {text}
                </a>
            </Link>
        );
    }
});
PrepareLink.displayName = 'PrepareLink';

export default function VideoCard({ site, index, pageTag, eventSender, eventData }: Type): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [imgSrc, setImgSrc] = useState(site.image);

    const router = useRouter();
    const observedThumb = useRef(null);
    const relCategory = 'nofollow noreferrer';

    useEffect(() => {
        setLoading(false);
    }, []);
    // This function finds out the correct event that needs to be sent to the Algolia
    // If the eventSender and eventData is available as props, that means Card is being rendered
    // in the Instant Search panel. In that case, we use those props to send the event. We also put the queryID
    // into a sessionStorage, so that we can send a clickedObjectIDsAfterSearch or convertedObjectIDsAfterSearch
    // event after you navigate to a review page.
    // The other way is sending the clickedObjectIDs or convertedObjectIDs events without the queryIDs
    // @param eventType can be 'conversion' or 'click'
    // @param eventName name to describe where the event is being clicked on ex. 'Card Full Review','Card Thumbnail'
    function createAlgoliaEvent(eventType: string, eventName: string) {
        if (eventSender && eventData) {
            const sessionStorageData = {
                queryID: eventData.__queryID,
                reviewSlug: site.siteItemProps.id
            };
            sessionStorage.setItem('algoliaQuery', JSON.stringify(sessionStorageData));
            eventSender(eventType, eventData, eventName);
        } else {
            aa('init', {
                appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
                apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
                useCookie: true, // since v2, this is false by default
                cookieDuration: 60 * 60 * 1000 // one hour, in milliseconds (default: 15552000000)
            });
            const algoliaType = eventType === 'click' ? 'clickedObjectIDs' : 'convertedObjectIDs';
            let sortValue = '';
            // If the card is on a Tag or Category page, we need to include the active tab in the event
            // before sending it to Algolia.
            if (router.pathname.indexOf('/porn/categories/') > -1 || router.pathname.indexOf('/porn/tag/') > -1) {
                sortValue =
                    router.query && router.query.sort
                        ? ` ${String(router.query.sort).charAt(0).toUpperCase() + String(router.query.sort).slice(1)}`
                        : ' Relevance';
            }
            //64 characters is the max we can send to Algolia, that's why there's a split used for the eventName
            aa(algoliaType, {
                index: 'RRX',
                eventName: String(`${eventName}${sortValue}${router && router.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${site.siteItemProps.id}`]
            });
        }
    }

    const thumb = undefined;
    const siteLink = undefined;

    const ThumbLoger = (InView, ThumbID, thumb, siteLink) => {
        if (InView === true && loading === false) {
            if (typeof thumb === 'undefined') thumb = 'il';
            if (typeof siteLink === 'undefined') siteLink = 'p';
            if (typeof ThumbID === 'undefined') ThumbID = 0;
            try {
                const lpath = process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/pthumb/' + ThumbID;
                fetch(lpath, {
                    method: 'POST'
                }).catch(function (e) {
                    // do nothing
                });
            } catch (err) {
                // do nothing
            }
        }
    };
    return (
        <>
            <div className={styles.container}>
                {/* Thumbnail */}
                <div className={`${styles.head}`}>
                    <div className={styles.image} ref={observedThumb}>
                        <a
                            href={site.tourlink}
                            rel={relCategory}
                            target="_blank"
                            title={'Visit ' + site.reviewName}
                            data-opensite-action={`${site.reviewName}-thumb`}
                            data-opensite-category="Opensite"
                            data-opensite-path={`outgoing,${site.reviewName},thumb`}
                            data-opensite-site={site.reviewID}
                            onClick={() => createAlgoliaEvent('conversion', 'Card Thumbnail')}>
                            <InView
                                triggerOnce
                                onChange={(InView) => {
                                    ThumbLoger(InView, site.reviewID, thumb, siteLink);
                                }}>
                                <Image
                                    unoptimized
                                    alt={`Site thumbnail`}
                                    src={imgSrc}
                                    onError={() => {
                                        setImgSrc('https://assets.rabbitsreviews.com/images/rabbitslogo.png');
                                    }}
                                    layout="fill"
                                    objectFit="cover"
                                    data-thumbid={site.videoTitle}
                                    priority={index < 3 ? true : false}
                                    className={styles.image}
                                />
                            </InView>
                        </a>
                        {site.videoQuality.toLowerCase() !== 'n/a' && (
                            <div className={`${styles.label}`}>{site.videoQuality}</div>
                        )}

                        <PrepareLink text={site.category} slug={site.categorySlug} type={'categories'} />
                    </div>
                </div>
                {/* Thumbnail End */}
                <div className={`${styles.content}`}>
                    <div className={`${styles.text} ${styles.corner}`}>
                        <h3>
                            <Link href={site.tourlink} passHref>
                                <a className={styles.title} target="_blank" rel="noopener noreferrer">
                                    {site.videoTitle}
                                </a>
                            </Link>
                        </h3>
                        <div className={styles.rating}>
                            {site.duration && site.duration !== '0' && site.duration.toLowerCase() !== 'n/a'
                                ? `${site.duration}mins`
                                : ''}
                        </div>
                    </div>
                    <div className={`${styles.text} ${styles.corner}`}>
                        <div className={styles.subtitle}>
                            {!router.pathname.includes('reviews') && (
                                <PrepareLink text={site.reviewName} slug={site.reviewSlug} type={'reviews'} />
                            )}
                        </div>
                        <div className={styles.date}>{site.datePublished}</div>
                    </div>

                    <div className={styles.features}>
                        {site.stars.map((star: Record<string, string>, index: number) => {
                            if (star.slug) {
                                if (star.slug.toLowerCase() == 'videocards') {
                                    return <span key={index}>{star.name}</span>;
                                } else {
                                    return (
                                        <Link
                                            key={index}
                                            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/stars/${star.id}/${star.slug}`}>
                                            <a href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/stars/${star.id}/${star.slug}`}>
                                                <span className={styles.active}>{star.name}</span>
                                            </a>
                                        </Link>
                                    );
                                }
                            } else {
                                return (
                                    <span className={styles.inactive} key={index}>
                                        {star.name}
                                    </span>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
